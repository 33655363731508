import { useState, useEffect, useRef, memo, useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { PHONE_ICON, SUPPORT_CENTER_ICON, TELEGRAM_ICON } from 'constants/Icons';
import { PHONE_NUMBER_CONTACT, TELE_URL } from 'constants/data/contact';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.css';
import DraggableComponent from '../DraggableContainer';

const IconChat = dynamic(() => import('components-v2/atoms/IconChat'), { ssr: false });
const ChatThuocSi = dynamic(() => import('utils/ChatThuocsi'), { ssr: false });

function SupportCenter({ isMobile = true, isShowIconFBPage = false, isAuthenticated = false, toggleChat }) {
  const [open, setOpen] = useState(false);
  const { t: translateCommon } = useTranslation('common');
  const [dragging, setDragging] = useState(false);

  const scrollPositionRef = useRef(0);

  useEffect(() => {
    const handleDisplay = () => {
      const footerElm = document.getElementById('app-footer');
      const contactElm = document.getElementById('support-center');
      if (!footerElm || !contactElm) return;

      const isScrollUp = window.scrollY < scrollPositionRef.current;
      scrollPositionRef.current = window.scrollY;

      const remainHeight = document.body.scrollHeight - footerElm.clientHeight;
      const isHideContact = remainHeight - (window.scrollY + window.screen.height) < 0;

      if (isHideContact) {
        contactElm.classList.add(styles.close);
      } else if (isScrollUp) {
        contactElm.classList.remove(styles.close);
      }
    };

    window.addEventListener('scroll', handleDisplay);
    return () => {
      window.removeEventListener('scroll', handleDisplay);
    };
  }, []);

  const toggleSuportCenter = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  return (
    <>
      <DraggableComponent initialPosition={{ bottom: 50, right: 16 }} dragging={dragging} setDragging={setDragging}>
        <div>
          <div className={styles.relativeWrapper} id="support-center">
            <img
              src={SUPPORT_CENTER_ICON}
              alt="support center"
              className={`${styles.closedSupportWrapper} ${open ? styles.close : styles.open}`}
              onClick={toggleSuportCenter}
              role="presentation"
              draggable={false}
            />
            <div className={`${styles.supportWrapper} ${!open ? styles.close : styles.open}`}>
              <div className={styles.titleWrapper}>
                <span className={styles.title}>{translateCommon('support_center.title')}</span>
                <CloseIcon onClick={toggleSuportCenter} className={styles.closeIcon} />
              </div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <a href={`tel:${PHONE_NUMBER_CONTACT.replace(/-/gi, '')}`} target="_blank" rel="noreferrer" className={styles.itemImg}>
                    <img src={PHONE_ICON} alt={PHONE_NUMBER_CONTACT} />
                  </a>

                  <span className={styles.itemName}>Phone</span>
                </div>
                <div className={styles.item}>
                  <a href={TELE_URL} target="_blank" rel="noreferrer" className={styles.itemImg}>
                    <img src={TELEGRAM_ICON} alt={TELE_URL} />
                  </a>

                  <span className={styles.itemName}>Telegram</span>
                </div>
                <div className={styles.item}>
                  <div className={styles.chatContainer}>
                    {isMobile && isShowIconFBPage && <IconChat isAuthenticated={isAuthenticated} onClick={toggleChat} className={styles.itemImg} />}
                    {!isMobile && <ChatThuocSi key={isAuthenticated || null} />}
                  </div>
                  <span className={styles.itemName}>Chat</span>
                </div>
              </div>
            </div>
          </div>
          {isMobile && open && <div className={styles.overlay} onClick={toggleSuportCenter} onScroll={toggleSuportCenter} role="presentation" />}
        </div>
      </DraggableComponent>
    </>
  );
}

export default memo(SupportCenter);
