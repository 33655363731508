const listStorageNotToClear = ['ins', 'sp', 'videoGuide'];
const clearStorage = () => {
  if (window && window.localStorage) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in localStorage) {
      if (!listStorageNotToClear.some((item) => key?.startsWith(item))) {
        localStorage.removeItem(key);
      }
    }
  }
};

export default {
  clearStorage,
};
