const PHONE_REGEX = /^(09|07|08|05|03|02[0|1|2|3|4|5|6|7|8|9])+([0-9]{8})\b/;
const EMAIL_REGEX = /^$|^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const PHONE_REGEX_CAM = /^([0-9]{9,10})\b/;
const PHONE_REGEX_VN = /^(09|07|08|05|03|02[0|1|2|3|4|5|6|7|8|9])+([0-9]{8})\b/;

export default {
  PHONE_REGEX,
  PHONE_REGEX_VN,
  PHONE_REGEX_CAM,
  EMAIL_REGEX,
};
