import { GET } from './Clients';

async function getAppList({ name }) {
  return GET({ url: `/core/config-manager/v1/app/list?q=${JSON.stringify({ name })}`, isBasic: true });
}

async function getAppValue({ appCode }) {
  return GET({ url: `/core/config-manager/v1/app-value/list?q=${JSON.stringify({ appCode })}`, isBasic: true });
}

export default {
  getAppList,
  getAppValue,
};
