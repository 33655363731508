// const path = require('path');
const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;
const LocalStorageBackend = require('i18next-localstorage-backend').default;

const isBrowser = typeof window !== 'undefined';
const isDev = process.env.NODE_ENV === 'development';

module.exports = {
  i18n: {
    defaultLocale: 'cam',
    locales: ['vi', 'en', 'cam'],
  },
  debug: isDev,
  backend: {
    backendOptions: [{ expirationTime: isDev ? 0 : 60 * 60 * 1000 }, {}], // 1 hour
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
  },
  // localePath: path.resolve('./public/static/locales'),
  // ns: 'common',
  // defaultNS: 'common',
  // react: {
  //   useSuspense: false,
  //   wait: true,
  // },
  partialBundledLanguages: isBrowser && true,
  // react: { // used only for the lazy reload
  //   bindI18n: 'languageChanged loaded',
  //   useSuspense: false
  // },
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
};
