export const LINE_URL = 'https://line.me/R/ti/p/@616fszhu';
export const TELE_URL = 'https://t.me/buymed_cambodia';
export const PHONE_NUMBER_CONTACT = '+85598888437';
export const BRAND_NAME_SUPPORT = '@Buymed Cambodia';

export default {
  LINE_URL,
  PHONE_NUMBER_CONTACT,
  BRAND_NAME_SUPPORT,
};
